<template>
  <div class="reservation thin-scrollbar">
    <div class="inner">
      <div class="top">
        <div class="title font-md clearfix">
          <span class="float-left">{{ state.reservation.projectName }}</span>
          <span class="remove float-right pointer" title="오픈예정 등록 삭제" v-if="state.reservation.reservationSeq" @click="remove()"><i class="fa fa-trash"></i></span>
        </div>
        <div class="info font-sm clearfix">
          <span class="float-left">{{ state.reservation.memberName }}</span>
          <span class="float-right">{{ $lib.getDateFormat(state.reservation.investStartDate, "yyyy-MM-dd") }} 오픈</span>
        </div>
      </div>
      <div class="content">
        <div class="row align-items-end">
          <div :class="state.reservation.projectType === 'reward'? 'col-lg-6': 'col-lg-4'">
            <div class="d-flex justify-content-between">
              <label :for="`${component.name}Thumbnail`" class="font-sm">썸네일</label>
              <div class="font-xs color-secondary">미등록시 본 프로젝트 썸네일</div>
            </div>
            <div class="d-flex flex-row">
              <label class="file btn" title="클릭하여 썸네일 수정" :class="modalParams.isOpened ? 'disable': 'btn-default'">
                <input type="file" :id="`${component.name}Thumbnail`" :accept="$definitions.limits.fileExtensions.imgStr" class="hide" @change="setFile($event)" :disabled="modalParams.isOpened">
                <i class="fa fa-file-image-o"></i>
                <span v-if="state.file">{{ state.file.name }}</span>
                <span v-else-if="state.reservation.fileOrigName">{{ state.reservation.fileOrigName }}</span>
                <span v-else>이미지 등록</span>
              </label>
              <a v-if="state.file || state.reservation.fileOrigName" class="btn btn-default font-sm preview" :href="`/${$definitions.apiVersion}/download/api/admin/project/reservation/${state.reservation.reservationSeq}`" target="_blank"
                 rel="noopener noreferrer">
                <span>다운로드</span>
              </a>
            </div>
          </div>
          <div v-if="state.reservation.projectType === 'reward'" class="col-lg-6">
            <div class="d-flex flex-column">
              <label :for="`${component.name}Item`" class="font-sm">대표 리워드</label>
              <select :id="`${component.name}Item`" class="form-control" v-model="state.reservation.rewardSeq" @change="change($event)" :disabled="!state.reservation.rewards.length || state.reservation.isOpened">
                <option :value="null">대표 리워드를 선택해주세요</option>
                <option :value="r.rewardSeq" v-for="(r, idx) in state.reservation.rewards" :key="idx">{{ r.title }} &nbsp;{{ $lib.getNumberFormat(r.rewardAmt) }} 원</option>
              </select>
            </div>
          </div>
          <div :class="state.reservation.projectType === 'reward'? 'col-lg-6':'col-lg-4'">
            <label :for="`${component.name}OpenStatus`" class="font-sm">공개 범위</label>
            <select :id="`${component.name}OpenStatus`" class="form-control" v-model="state.reservation.openStatus" :disabled="modalParams.isOpened">
              <option value="BEFORE_OPEN">공개하지 않음</option>
              <option value="ONLY_SIMPLE_TEXT">간단 소개만 공개</option>
              <option value="OPEN_ALL">전체 공개</option>
            </select>
          </div>
          <div :class="state.reservation.projectType === 'reward'? 'col-lg-6':'col-lg-4'">
            <div class="box form-control" :class="{disabled: modalParams.isOpened}">
              <span class="font-sm">상세페이지 오픈 예정일 숨기기</span>
              <label class="switch">
                <input type="checkbox" :checked="state.reservation.hideOpenDateYn === 'Y'" :disabled="modalParams.isOpened" @change="check($event, 'hideOpenDateYn')">
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>
        <div class="mt-4">
          <div class="d-flex justify-content-between">
            <label class="font-sm" :for="`${component.name}ShortIntro`">
              <span class="color-purple">* </span>
              <span>간단 소개</span>
              <span class="limit">({{ state.reservation.simpleText ? computedSimpleTextLen : 0 }}/{{ simpleTextLimits.maxlength }})</span>
            </label>
            <div class="font-xs pointer" @click="prompt()">챗GPT 추천</div>
          </div>
          <textarea class="form-control" :id="`${component.name}ShortIntro`" placeholder="프로젝트 소개글을 50자 이상 100자 이내로 요약하여 작성해주세요." :value="state.reservation.simpleText"
                    @input="type($event)" :minlength="simpleTextLimits.minlength" :maxlength="simpleTextLimits.maxlength" :disabled="modalParams.isOpened"></textarea>
        </div>
        <div class="mt-4" v-if="!modalParams.isOpened">
          <div class="d-flex justify-content-between align-items-center mb-2">
            <label class="font-sm color-secondary mb-0">상세 내용</label>
            <div class="form-check mr-2 d-flex align-items-center" v-if="state.reservation.projectType === 'reward'">
              <input type="checkbox" class="form-check-input" :id="`${component.name}Check`" @change="check($event, 'contentRefYn')" :checked="state.reservation.contentRefYn === 'Y'">
              <label :for="`${component.name}Check`" class="form-check-label font-xs">본 프로젝트 내용과 동일</label>
            </div>
          </div>
          <TinyEditor :value.sync="state.reservation.content" :height="335" ref="editorRef" v-if="state.reservation.contentRefYn === 'N'"/>
          <span class="font-sm text-center pt-3 pb-3 d-block" v-else>본 프로젝트 내용이 그대로 적용됩니다.</span>
        </div>
        <div class="mt-4" v-else>
          <label>상세 내용</label>
          <div class="text font-sm" v-html="state.reservation.content || ((state.reservation.contentRefYn === 'Y' && state.reservation.projectType === 'reward') ? '본 프로젝트 내용이 그대로 적용된 프로젝트 입니다.' : '상세 내용 없이 등록된 프로젝트 입니다.')"></div>
        </div>
      </div>
    </div>
    <div class="actions d-flex" :class="{invest: state.reservation.projectType === 'invest'}" v-if="!modalParams.isOpened">
      <button class="btn btn-primary btn-block" @click="submit()">{{ state.reservation.reservationSeq ? "저장하기" : "등록하기" }}</button>
    </div>
  </div>
</template>

<script>import {computed, defineComponent, reactive, ref} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import http from "@/scripts/http";
import TinyEditor from "@/components/TinyEditor";
import Anchor from "@/components/Anchor";

function Component(initialize) {
  this.name = "modalReservation";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Anchor, TinyEditor},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "xm"});
      load();
    });

    const state = reactive({
      reservation: {
        content: "",
        contentRefYn: "N",
        fileOrigName: "",
        hideOpenDateYn: "N",
        memberName: "",
        item: "",
        investStartDate: "",
        openStatus: "ONLY_SIMPLE_TEXT",
        projectType: "",
        projectSeq: null,
        projectName: "",
        price: null,
        rewardSeq: null,
        reservationSeq: null,
        rewards: [],
        simpleText: "",
        thumbFileUrl: "",
      },
      file: null,
    });

    const modalParams = store.getters.modalParams(component);
    const editorRef = ref();

    const simpleTextLimits = {
      minlength: 50,
      maxlength: 100,
    };

    const computedSimpleTextLen = computed(() => {
      return state.reservation.simpleText?.trim()?.length;
    });

    const load = () => {
      http.get(`/api/admin/project/reservation/${modalParams.projectType}/${modalParams.projectSeq}`).then(({data}) => {
        for (let i in state.reservation) {
          data.body[i] && (state.reservation[i] = data.body[i]);
        }

        data.body.reservationSeq && (state.reservationSeq = data.body.reservationSeq);

        if (state.reservation.projectType === "reward" && data.body.rewards?.findIndex(r => r.title === state.reservation.item) >= 0) {
          const reward = data.body.rewards[data.body.rewards.findIndex(r => r.title === state.reservation.item)];
          state.reservation.rewardSeq = reward?.rewardSeq || null;
          state.reservation.price = reward?.rewardAmt;
        }
      });
    };

    const setFile = (e) => {
      if (!store.getters.isAllowedExtension(e.target, "image")) {
        return;
      }

      state.file = e.target.files[0];
      e.target.value = "";
    };

    const upload = async (reservationSeq) => {
      if (!reservationSeq || !state.file) {
        return;
      }

      const formData = new FormData();
      formData.append("file", state.file);

      await http.post(`/api/admin/project/reservation/${reservationSeq}/file`, formData);
    };

    const prompt = () => {
      const args = {
        topic: "reservation",
        prompt: `크라우드 펀딩 소개 문구를 한글로 작성해줘. 제목은 ${state.reservation.projectName}. 130 byte 이하로.`
      };

      http.post(`/api/chatgpt`, args).then(({data}) => {
        if (data.code === "200 OK") {
          state.reservation.simpleText = (data.body || "").trim().split("\"").join("");
        } else {
          store.commit("setSwingMessage", data.message);
        }
      });
    };

    const submit = async () => {
      const $text = document.getElementById(`${component.name}ShortIntro`);

      if (computedSimpleTextLen.value < simpleTextLimits.minlength) {
        $text.focus();
        return store.commit("setSwingMessage", `간단 소개를 ${simpleTextLimits.minlength}자 이상 작성해주세요.`);
      } else if (computedSimpleTextLen.value > simpleTextLimits.maxlength) {
        $text.focus();
        return store.commit("setSwingMessage", `간단 소개를 ${simpleTextLimits.maxlength}자 이하로 작성해주세요.`);
      }

      const args = {
        projectSeq: state.reservation.projectSeq,
        projectType: state.reservation.projectType,
        simpleText: state.reservation.simpleText,
        price: state.reservation.price,
        item: state.reservation.item,
        hideOpenDateYn: state.reservation.hideOpenDateYn,
        content: state.reservation.content,
        openStatus: state.reservation.openStatus,
        contentRefYn: state.reservation.contentRefYn,
      };

      if (state.reservation.reservationSeq) {
        await http.put(`/api/admin/project/reservation/${state.reservation.reservationSeq}`, args);
        await upload(state.reservation.reservationSeq);
        close("오픈 예정 프로젝트를 저장하였습니다.");
      } else {
        const result = await http.post(`/api/admin/project/reservation`, args);
        await upload(result.data.body);
        close("오픈 예정 프로젝트를 등록하였습니다.");

        state.reservation.reservationSeq = result.data.body;
      }
    };

    const remove = () => {
      store.commit("confirm", {
        message: "이 프로젝트를 삭제하시겠습니까?",
        allow() {
          http.delete(`/api/admin/project/reservation/${state.reservation.reservationSeq}`).then(() => {
            close("오픈예정 프로젝트를 삭제하였습니다.");
          });
        }
      });
    };

    const type = (e) => {
      state.reservation.simpleText = e.target.value;
    };

    const change = (e) => {
      if (state.reservation.projectType !== "reward") {
        return;
      }

      const index = state.reservation.rewards.findIndex(r => r.rewardSeq === Number(e.target.value));

      state.reservation.price = state.reservation.rewards[index]?.rewardAmt;
      state.reservation.item = state.reservation.rewards[index]?.title;
    };

    const check = (e, key) => {
      if (e.target.checked) {
        state.reservation[key] = "Y";
      } else {
        state.reservation[key] = "N";
      }
    };

    const close = (message) => {
      store.commit("closeModal", {
        name: component.name,
        onClose(modal) {
          store.dispatch("callback", {modal});
          store.commit("setSwingMessage", message);
        }
      });
    };

    return {component, state, modalParams, simpleTextLimits, editorRef, computedSimpleTextLen, setFile, prompt, submit, type, remove, change, check};
  }
});
</script>

<style lang="scss" scoped>
.reservation {
  .inner {
    background: #fff;
    min-height: $px200;
    padding: $px25;

    > .top {
      padding-bottom: $px10;
    }

    .form-control, .btn {
      height: $formHeight;

      &.disabled {
        background: #e9ecef;

        label.switch input:checked + .slider {
          background: $colorSecondary;
        }
      }
    }

    .title {
      margin-bottom: $px9;

      i {
        color: $colorSecondary;
      }
    }

    .info {
      margin-bottom: $px25;
      color: #666;
    }

    .content > div {
      padding-bottom: $px5;

      .file {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        span, i {
          vertical-align: middle;
        }

        i {
          margin-right: $px5;
        }

        &.disable {
          background: #e9ecef;
          color: inherit;
          cursor: default;
        }
      }

      .limit {
        font-size: $px12;
        color: $colorSecondary;
      }

      textarea {
        transition: none;
        min-height: $px100;
      }

      .text {
        border: $px1 solid $colorBorder;
        padding: $px15;
      }

      .box {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      label.switch {
        position: relative;
        display: inline-block;
        width: $px50;
        height: $px21;
        margin-bottom: 0;

        > input {
          opacity: 0;
          width: 0;
          height: 0;

          &:checked + .slider {
            background-color: $colorPurple;

            &::before {
              transform: translate($px29, -50%);
            }
          }

          &:focus + .slider {
            box-shadow: 0 0 $px1 #2196F3;
          }
        }

        > .slider {
          cursor: pointer;
          background-color: #ccc;
          display: inline-block;
          position: relative;
          width: 100%;
          height: 100%;
          transition: .4s;

          &.round {
            border-radius: $px50;

            &::before {
              border-radius: 50%;
            }
          }

          &::before {
            position: absolute;
            content: "";
            height: $px13;
            width: $px13;
            left: $px4;
            top: 50%;
            transform: translateY(-50%);
            background-color: #fff;
            transition: 0.18s;
          }
        }
      }

      .preview {
        flex-shrink: 0;
      }
    }
  }

  .actions {
    .btn {
      border: none;
      border-radius: 0;
      margin: 0;
      padding: 0 $px20;
      height: $px60;

      &:hover {
        &.btn-primary {
          background: $colorPurpleActive;
        }

        &.btn-secondary {
          background: $colorSecondary;
          color: #fff;
        }
      }
    }
  }

}
</style>